import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import "../../components/prizes.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Geothermallithiumextraction = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Geothermal Lithium Extraction Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The American-Made Geothermal Lithium Extraction Prize is
                designed to find solutions that de-risk and increase market
                viability for direct lithium extraction (DLE) from geothermal
                brines. Advancement of DLE technologies allow for improved
                methods that lower cost, lessen environmental impact and further
                the mission to turn the threat of climate change into an
                opportunity to revitalize the U.S. energy and critical materials
                supply chain.
              </p>
              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/geothermallithiumextraction">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy" />
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">The Geothermal Lithium Extraction Prize</h1>
          <p className="pcol-fz">
            Lithium is a major component of high-charge-density batteries for
            electric vehicles and grid-scale electricity storage. It is expected
            that global demand for lithium will increase by 500% by 2050 due to
            widespread adoption of electric vehicles and grid-scale battery
            storage, making lithium supplies a crucial element in the clean
            energy supply chain.
          </p>
          <p className="pcol-fz">
            The current U.S. lithium stock is almost entirely imported, with
            only 1% of U.S. lithium supply being sourced domestically.
            Traditional sources of lithium are environmentally destructive and
            cost-prohibitive due to the complexity of the extraction process.
            The combination of rapidly expanding global demand and lack of a
            secure domestic supply has created an urgency to develop a safe,
            domestic, and cost-competitive source of lithium. Advancements in
            domestic geothermal lithium extraction will also help ensure
            American leadership in the transition to a carbon-free economy and
            clean energy job creation throughout a robust domestic supply chain.
          </p>
          <h1 className="mac_h1">Prize Structure</h1>
          <div>
            <div className="row rww">
              <div className="col">
                <p className="pcol-fz">
                  The Geothermal Lithium Extraction Prize consists of three
                  phases that will fast-track efforts to identify, develop, and
                  test disruptive solutions to improve the profitability of DLE
                  from geothermal brines. Each stage will include a contest
                  period when participants work to rapidly advance their
                  solutions.
                </p>
                <button type="button" className="btns" disabled>
                  Learn about competing in the E-ROBOT Prize{" "}
                </button>
              </div>
              <div className="col">
                <StaticImage
                  src="../../images/geo-graph.webp"
                  className="img-styl"
                  alt="geo graph"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className>
            <h1 className="mac_h1">Important Dates</h1>
            <h4> Phase 1: Idea and Concept</h4>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>Phase 1 submission opens: March 31, 2021</li>
              <li>Phase 1 informational webinar: April 12, 2021</li>
              <li>Phase 1 submission deadline: July 2, 2021 5 p.m. ET</li>
              <li>
                Phase 1 semifinalists announced, and Phase 2 begins: Expected
                August 31, 2021
              </li>
            </ul>
            <hr />
            <h4> Phase 2 : Design and Invent</h4>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>Phase 2 submission opens: Expected August 2021</li>
              <li>
                Phase 2 virtual innovation pitches with IAP: Expected November
                2021
              </li>
              <li>Phase 2 submission deadline: Expected November 2021</li>
              <li>
                Phase 2 finalists announced, and Phase 3 begins: Expected
                January 2022
              </li>
            </ul>
            <hr />
            <h4> Phase 3: Fabricate and Test</h4>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>Phase 3 submission opens: Expected January 2022</li>
              <li>Phase 3 submission deadline: Expected January 2023</li>
              <li>Phase 3 winners announced: Expected February 2023</li>
            </ul>
            <hr />
            <p>
              All dates are subject to change including contest openings,
              deadlines, and announcements. Sign up for updates on our{" "}
              <a
                href="https://www.herox.com/geothermallithiumextraction"
                onclick="gtag('event', 'action', {'event_category': 'navigated to herox', 'event_label': 'linked to geothermal lithium extraction'})"
              >
                HeroX challenge page
              </a>
              .
            </p>
          </div>
          <hr />
        </div>
        <div className="container">
          <h3 className="h3ps"> Solutions that help shape the future </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="#">
              <button type="button" class="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Geothermallithiumextraction
